import { CancelledError } from "@/lib/async/cancelled-error";
import { Action, notificationManagerInstance } from "@/lib/managers/notification-manager";
import { ProgressNotification } from "@/notifications/progress-notification";
import type { StoreStreamResponse } from "@/stores/common";
import { observable } from "knockout";
import type { RowBase } from "./grid-store";
import type { PaginatedGridStoreParams } from "./paginated-grid-store";
import { LoadType, PaginatedGridStore } from "./paginated-grid-store";

export interface Cursor {
   startingBefore?: string | null;
   startingAt?: string | null;
   startingAfter?: string | null;
}

export interface KeysetQueryParams {
   starting_before?: string | null;
   starting_at?: string | null;
   starting_after?: string | null;
}

export interface ResponsePayload<Row extends RowBase> {
   data: Row[];
   pagination: {
      total_possible: number;
      next_starting_after: string | null;
      previous_starting_before: string | null;
   };
}

export type KeysetGridStoreParams<QueryParams extends KeysetQueryParams> = PaginatedGridStoreParams<
   QueryParams,
   Cursor
>;

/** Abstract store for working with keyset-based endpoints. */
export abstract class KeysetGridStore<
   Row extends RowBase,
   QueryParams extends KeysetQueryParams,
> extends PaginatedGridStore<Row, QueryParams, Cursor> {
   private startingBefore: string | null = null;
   private startingAt: string | null = null;
   private startingAfter: string | null = null;
   private loadingAllResponse: StoreStreamResponse<Row> | null = null;
   totalPossible = observable(0);

   constructor(params: KeysetGridStoreParams<QueryParams>) {
      super(params);
      const cursor = this.getCursor();
      if (cursor) {
         this.startingBefore = cursor.startingBefore || null;
         this.startingAt = cursor.startingAt || null;
         this.startingAfter = cursor.startingAfter || null;
      }
      this.hasPreviousRows(this.startingBefore != null || this.startingAt != null);
      this.hasNextRows(
         this.startingAfter != null || this.startingAt != null || this.startingBefore == null,
      );
   }

   /** Subclasses implement this method to load data. */
   protected abstract loadRows(
      queryParams: QueryParams,
   ): ResponsePayload<Row> | Promise<ResponsePayload<Row>>;

   /** Subclasses implement this method to load all data in the dataset. */
   protected abstract createLoadAllRowsStream(queryParams: QueryParams): StoreStreamResponse<Row>;

   protected createCursor(rowIndex: number): Cursor {
      const row = this.rows()[rowIndex];
      return {
         startingBefore: row.id,
         startingAfter: rowIndex - 1 >= 0 ? this.rows()[rowIndex - 1].id : null,
      };
   }

   protected loadRowsForType(loadType: LoadType, queryParams: QueryParams): Row[] | Promise<Row[]> {
      if (loadType == LoadType.PREVIOUS) {
         const result = this.loadRows({
            ...queryParams,
            starting_before: this.startingBefore,
         });
         const onSuccess = (payload: ResponsePayload<Row>) => {
            this.startingBefore = payload.pagination.previous_starting_before;
            this.hasPreviousRows(payload.pagination.previous_starting_before != null);
            this.totalPossible(Math.max(this.totalPossible(), payload.pagination.total_possible));
            return payload.data;
         };
         return result instanceof Promise ? result.then(onSuccess) : onSuccess(result);
      }

      const additional = this.startingAt
         ? { starting_at: this.startingAt }
         : this.startingAfter
         ? { starting_after: this.startingAfter }
         : {};

      const result = this.loadRows({ ...queryParams, ...additional });
      const onSuccess = (payload: ResponsePayload<Row>) => {
         if (this.startingAt) {
            this.startingAt = null;
            this.startingBefore = payload.pagination.previous_starting_before;
            this.hasPreviousRows(this.startingBefore != null);
         }
         this.startingAfter = payload.pagination.next_starting_after;
         this.hasNextRows(payload.pagination.next_starting_after != null);
         this.totalPossible(Math.max(this.totalPossible(), payload.pagination.total_possible));
         return payload.data;
      };
      return result instanceof Promise ? result.then(onSuccess) : onSuccess(result);
   }

   async loadAll(): Promise<void> {
      if (this.isLoadingAll()) return;
      if (!this.hasNextRows() && !this.hasPreviousRows()) return;

      const notification = new ProgressNotification({
         message: "Loading all records...",
         actions: [
            new Action({
               text: "Cancel",
               type: Action.Type.RED,
               onClick: () => this.cancelLoadAll(),
            }),
         ],
      });

      notificationManagerInstance.show(notification);

      let batchAfter = [];
      try {
         this.isLoadingAll(true);
         this.loadingAllResponse = this.createLoadAllRowsStream(this.getQueryParams());
         const stream = await this.loadingAllResponse.stream;

         const previousRows = [];
         let isAccumulatingPreviousRows = this.hasPreviousRows();
         let isWaitingForNewRows = this.hasNextRows();
         const first = this.rows()[0];
         const last = this.rows()[this.rows().length - 1];

         let count = 0;
         for await (const row of stream) {
            notification.update({ percent: ++count / this.totalPossible() });
            if (isAccumulatingPreviousRows) {
               if (row.id == first.id) {
                  if (previousRows.length) this.rows.unshift(...previousRows);
                  this.hasPreviousRows(false);
                  isAccumulatingPreviousRows = false;
               } else {
                  previousRows.push(row);
               }
            } else if (isWaitingForNewRows) {
               if (row.id == last.id) {
                  isWaitingForNewRows = false;
               }
            } else {
               if (batchAfter.length > 20) {
                  this.rows.push(...batchAfter);
                  batchAfter = [];
               }
               batchAfter.push(row);
            }
         }
         // We may have left over rows in the batch so lets go ahead and add them to the rows.
         this.rows.push(...batchAfter);

         this.hasNextRows(false);
         notification.success({
            message: "All records have been loaded.",
            actions: [],
         });
      } catch (error) {
         if (batchAfter.length) this.rows.push(...batchAfter);
         notification.failed({
            message:
               error instanceof CancelledError
                  ? "Loading all records was cancelled."
                  : "An unexpected error occurred while loading.",
            actions: [],
         });
      } finally {
         this.loadingAllResponse = null;
         this.isLoadingAll(false);
         setTimeout(() => {
            notificationManagerInstance.dismiss(notification);
         }, 5000);
      }
   }

   cancelLoadAll(): void {
      if (this.loadingAllResponse) this.loadingAllResponse.cancel();
   }
}
