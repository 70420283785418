import { Url as UrlUtil } from "@/lib/utils/url"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { Store } from "@/stores/store"

### Models ###
import { ValueSet } from "@/models/value-set"

export class GroupStore extends Store
   constructor: ->
      super()
      @vault = {
         "group-options": null
         "available-groups": null
      }

   getGroupOptions: (callback) ->
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/group-options"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         valueSets = data.map (groupValueSet) ->
            new ValueSet(groupValueSet)
         callback(null, valueSets)

   checkGroupApplication: (groupId, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/groups/#{groupId}/application"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         assertOfType(data.project_names, arrayOf(String))
         assertOfType(data.people_names, arrayOf(String))
         callback(null, data)

   getGroupEntities: (groupId, entities, callback) ->
      assertArgs(arguments, String, arrayOf(String), Function)
      params = UrlUtil.serializeParams({entities: entities})
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/groups/#{groupId}/entity-options?#{params}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         formattedData = {}
         if data.people_options?
            formattedData['peopleOptions'] = data.people_options.map (item) -> return new ValueSet(item)
         if data.user_options?
            formattedData['userOptions'] = data.user_options.map (item) -> return new ValueSet(item)
         if data.tag_options?
            formattedData['tagOptions'] = data.tag_options.map (item) -> return new ValueSet(item)
         if data.categorized_tag_options?
            categorizedTags = {}
            for item in data.categorized_tag_options
               category = if item.category? then item.category else "Uncategorized"
               categorizedTags[category] = item.tags.map (item) ->
                  return new ValueSet({name: item.name, value: item.id, color: item.color})

            formattedData['categorizedTagOptions'] = categorizedTags
         if data.position_options?
            formattedData['positionOptions'] = data.position_options.map (item) -> return new ValueSet(item)
         if data.project_options?
            formattedData['projectOptions'] = data.project_options.map (item) -> return new ValueSet(item)
         if data.active_project_options?
            formattedData['activeProjectOptions'] = data.active_project_options.map (item) -> return new ValueSet(item)
         if data.pending_project_options?
            formattedData['pendingProjectOptions'] = data.pending_project_options.map (item) -> return new ValueSet(item)
         if data.inactive_project_options?
            formattedData['inactiveProjectOptions'] = data.inactive_project_options.map (item) -> return new ValueSet(item)

         # Assignments Custom Field Filters
         if data.assignments_custom_field_filters?
            assignmentsCustomFieldFilters = []
            for field in data.assignments_custom_field_filters
               if field.type == "multi-select" or field.type == "select"
                  if field.sort_by == "alpha"
                     sortedValues = field.values.sort (a, b) ->
                        return if String(a).toLowerCase() > String(b).toLowerCase() then 1 else -1
                     field.values = sortedValues
                     assignmentsCustomFieldFilters.push(field)
                  else
                     assignmentsCustomFieldFilters.push(field)
               else
                  assignmentsCustomFieldFilters.push(field)
            formattedData['assignmentsCustomFieldFilters'] = assignmentsCustomFieldFilters

         # People Custom Field Filters
         if data.people_custom_field_filters?
            peopleCustomFieldFilters = []
            for field in data.people_custom_field_filters
               if field.type == "multi-select" or field.type == "select"
                  if field.sort_by == "alpha"
                     sortedValues = field.values.sort (a, b) ->
                        return if String(a).toLowerCase() > String(b).toLowerCase() then 1 else -1
                     field.values = sortedValues
                     peopleCustomFieldFilters.push(field)
                  else
                     peopleCustomFieldFilters.push(field)
               else
                  peopleCustomFieldFilters.push(field)
            formattedData['peopleCustomFieldFilters'] = peopleCustomFieldFilters

         # Projects Custom Field Filters
         if data.projects_custom_field_filters?
            projectsCustomFieldFilters = []
            for field in data.projects_custom_field_filters
               if field.type == "multi-select" or field.type == "select"
                  if field.sort_by == "alpha"
                     sortedValues = field.values.sort (a, b) ->
                        return if String(a).toLowerCase() > String(b).toLowerCase() then 1 else -1
                     field.values = sortedValues
                     projectsCustomFieldFilters.push(field)
                  else
                     projectsCustomFieldFilters.push(field)
               else
                  projectsCustomFieldFilters.push(field)
            formattedData['projectsCustomFieldFilters'] = projectsCustomFieldFilters

         # Requests Custom Field Filters
         if data.requests_custom_field_filters?
            requestsCustomFieldFilters = []
            for field in data.requests_custom_field_filters
               if field.type == "multi-select" or field.type == "select"
                  if field.sort_by == "alpha"
                     sortedValues = field.values.sort (a, b) ->
                        return if String(a).toLowerCase() > String(b).toLowerCase() then 1 else -1
                     field.values = sortedValues
                     requestsCustomFieldFilters.push(field)
                  else
                     requestsCustomFieldFilters.push(field)
               else
                  requestsCustomFieldFilters.push(field)
            formattedData['requestsCustomFieldFilters'] = requestsCustomFieldFilters

         if data.people_custom_fields?
            formattedData['peopleCustomFields'] = data.people_custom_fields
         if data.projects_custom_fields?
            formattedData['projectsCustomFields'] = data.projects_custom_fields

         if data.grouped_cost_code_options?
            groupedCostCodeOptions = {}
            for key, val of data.grouped_cost_code_options
               groupedCostCodeOptions[key] = val.map (item) ->
                  return new ValueSet(item)
            formattedData['groupedCostCodeOptions'] = groupedCostCodeOptions
         if data.grouped_label_options?
            groupedLabelOptions = {}
            for key, val of data.grouped_label_options
               groupedLabelOptions[key] = val.map (item) ->
                  return new ValueSet(item)
            formattedData['groupedLabelOptions'] = groupedLabelOptions
         callback(null, formattedData)


GroupStore.ValutKey = {
   GROUP_OPTIONS: "group-options"
   AVAILABLE_GROUPS: "available-groups"
}

export groupStore = new  GroupStore()